<template>
  <div class="send-cancellation-email-start-sidebar">
    <div class="send-cancellation-email-start-sidebar--content">
      <div style="display: flex; align-items: center; padding-top: 15px">
        <CRIcon view-box="0 0 24 24" :width="20" :height="20" color="red">
          alert_outline
        </CRIcon>
        <p style="padding-left: 8px; color: #e10055; margin: 0">
          {{ customerCancellationEmailSent ? 'Res' : 'S' }}end Cancellation
          Email for {{ managedId || '' }}?
        </p>
      </div>
    </div>
    <CRButton
      id="send-cancellation-email-start-save-btn"
      :loading="loading"
      class="send-cancellation-email-start-sidebar action-btn"
      :style="`width: ${customerCancellationEmailSent ? '525px' : '500px'}`"
      color="primary"
      text-color="white"
      @click="submit"
    >
      {{ customerCancellationEmailSent ? 'Res' : 'S' }}end
    </CRButton>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/event-bus'

export default {
  components: {},
  props: {
    managedId: {
      type: String,
      default: null,
    },
    customerCancellationEmailSent: {
      type: Boolean,
      default: false,
    },
    reservationId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async submit() {
      this.loading = true

      const success = await this.$store.dispatch('reservations/sendCancellationStartEmail', {
        reservationId: this.reservationId,
      })
      this.loading = false
      if (success) {
        this.$store.dispatch('app/showAlert', {
          color: 'success',
          message: `Cancellation start email has been ${
            this.customerCancellationEmailSent ? 're' : ''
          }sent.`,
        })
        EventBus.$emit('refresh-detail')
      } else {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: `Failed to ${
            this.customerCancellationEmailSent ? 're' : ''
          }send cancellation start email.`,
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.send-cancellation-email-start-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px 40px 0 40px;
  }
}

.action-btn {
  display: flex;
  position: fixed;
  flex-direction: column;
  font-size: 18px;
  bottom: 0;
  height: 71px !important;
  padding: 24px 0;
  width: inherit;
  border-radius: 0;
  background: $primary;
  z-index: 3;
}

::v-deep .accent--text {
  color: $blue !important;
}
</style>
